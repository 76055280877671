/*!
 * Font Awesome Pro 6.0.0-beta1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa{font-family:var(--fa,var(--fa,"Font Awesome 6 Pro"));font-weight:900;font-weight:var(--fa-style,900)}.fa,.fa-brands,.fa-duotone,.fa-light,.fa-regular,.fa-solid,.fa-thin,.fab,.fad,.fal,.far,.fas,.fat{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;display:var(--fa-display,inline-block);font-style:normal;font-variant:normal;line-height:1;text-rendering:auto}.fa-times:before{content:"\d7"}.fa-bars:before{content:"\f0c9"}.fa-magnifying-glass:before{content:"\1f50d"}.fa-chevron-down:before{content:"\f078"}.fa-chevron-left:before{content:"\2329"}.fa-chevron-right:before{content:"\232a"}.fa-arrow-left-long:before,.fa-long-arrow-left:before{content:"\f177"}.fa-arrow-right-long:before,.fa-long-arrow-right:before{content:"\f178"}.fa-calendar:before{content:"\1f4c5"}.fa-phone:before{content:"\1f4de"}.fa-dash:before{content:"\2014"}.fa-circle-info:before,.fa-info-circle:before{content:"\f05a"}.fa-circle-exclamation:before,.fa-exclamation-circle:before{content:"\f06a"}.fa-check:before{content:"\2713"}.fa-angle-left:before{content:"\2039"}.fa-angle-right:before{content:"\203a"}.fa-plus:before{content:"\2b"}.fa-share:before{content:"\f064"}.fa-arrow-rotate-left::before{content:"\21ba";}.fa-bicycle::before{content:"\f206";}.fa-car-side::before{content:"\f5e4";}.fa-play::before{content:"\25b6";}


.fa-facebook-f:before{content:"\f39e"}.fa-instagram:before{content:"\f16d"}.fa-linkedin-in:before{content:"\f0e1"}.fa-youtube:before{content:"\f167"}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url('../font/fa-light-300.woff2') format("woff2"), url('../font/fa-light-300.woff') format("woff");
}

.fal, .fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../font/fa-regular-400.woff2") format("woff2"), url("../font/fa-regular-400.woff") format("woff");
}

.far, .fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../font/fa-brands-400.woff2") format("woff2"), url("../font/fa-brands-400.woff") format("woff");
}

.fab, .fa-brands {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}